@import "../../style/color.scss";

.flex_container {
	display:flex;
	gap: 30px;
	align-items: center;
	padding-bottom: 50px;

	&>div:nth-child(1) {
		flex: 0 0 50%;
		text-align: center;
	}

	&>div {
		flex: 0 0 50%;
		text-align: center;
	}
}

.text {
	white-space: pre-wrap;
	line-height: 20px;
}

.description {
	text-align: left !important;
}

.docTitle {
	font-size: 38px;
	font-family: 'TTBricksBold';
	padding-bottom: 15px;
	margin: 0;
	
	&>a {
		color: #000;
	}
}

@media (max-width: 768px) {
	.flex_container:nth-child(3) {
		flex-direction: column-reverse;
	}

	.flex_container {
		flex-direction: column;



	}
}