.contacts {
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.address {
	text-align: left;
}

.phones {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;

	& a {
		color: #ccc;
	}

	& a:hover {
		color: #fff;
	}
}
