@import "../../style/color.scss";

.flex_container {
	// display: flex;
	// gap: 15px;

	& menu,
	ol,
	ul {
		list-style: inherit;
		padding-left: 40px;
	}

	

	& h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font: none;
	}
}

.text p {
	white-space: pre-wrap;
	line-height: 20px;
}

.img > img {
	min-width: 350px;
	max-width: 100%;
}

.contactList {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap:50px;
	column-gap:50px;
	margin-top: 50px;
	margin-bottom: 50px;
}

.description {
	margin-top: 10px;
	white-space: pre-wrap;
}

.flexMap {
	margin-top: 15px;
	display: flex;
	gap: 15px;

	&>div {
		flex: 1 0 45%
	}
}

.flexMapInfo {
	margin-top: 15px;
	display: flex;
	align-items: flex-end;
	gap: 15px;

	&>div {
		flex: 1 0 45%
	}
}

.areaItem {
	margin-top: 10px;
}

.parkingDescription, .areaDescription {
	display: none;
}

@media (max-width: 1024px) {
	.flexMapInfo {
		display: none;
	}

	.flexMap {
		flex-direction: column;
	}

	.areaDescription {
		display: block;
		margin-bottom: 15px;
	}

	.parkingDescription {
		display: block;
		margin-bottom: 15px;
	}
}

@media (max-width: 768px) {
	.contactList {
		grid-template-columns: 1fr;
	}
}