@import "../../style/color.scss";

.inputFile {
	line-height: 30px;
	font-size: 24px;
}

.imageContainer {
	display: grid;
	gap: 5px;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	grid-auto-rows: 1fr;
	flex-wrap: wrap;
	justify-content: stretch;
	// min-height: 100px;
	padding: 10px 0px 25px 0px;

}

.imgItem {
	
	// flex: 1 1 25%;
	// padding-top: 10px;
	// padding-bottom: 10px;
	max-height: 100px;
	border: 1px solid $first-color;
	padding: 5px 10px;
	color: $first-color;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// max-width: 24.25%;

	&:nth-child(2) {
		display: block;
		position: absolute;
	}
}

.img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	object-position: center center;
}
