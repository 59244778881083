.description {
	margin-top: 5%;
	max-width: 900px;
	line-height: 1.5;
	white-space: pre-wrap
}

.title {
	margin-top: 10%;
}

.ShowListContainer {
	// height: 400px;
	width: 100%;
	min-width: 300px;
}

.showItemImg {
	height: 300px;
}

.showItemImg img {
	width: 100%;
	height: 100%;
	object-fit: cover
}

.showName {
	text-transform: uppercase;
	font-size: 20px;
	font-family: "TTBricksBold";
	margin-top: 10px;
	margin-bottom: 15px;
	color: #333;
}

.swiper-button-prev {
	color: red; 
}

.swiper-button-next::after {
	color: #000 !important; 
}

.Shows {
	display: flex;
	flex-direction: column;
	// max-width: 70vw;
}

@media (max-width: 425px) {
	.title {
		margin-top: 0;
	}

	.Shows {
		max-width: 100%;
	}
}