@import "../../style/color.scss";

.flex_container {
	// display:flex;
	// gap: 15px
}

.carousel {
	margin-bottom: 35px;
}

.carouselLitle {
	margin-bottom: 5px;
}

// .imageContainer {
// 	height: 33vw;
// }

// .imageLitleContainer {
// 	height: 10vw;
// }

.carouselItem {
	position: relative;
	width: 100%;
   padding-top: 56.25%;
}

.carouselImg {
	position:  absolute;
	top: 0;
   left: 0;
   bottom: 0;
   right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.text {
	white-space: pre-wrap;
	line-height: 20px;
}

.img > img {
	min-width: 350px;
	max-width: 100%;
}

.areaList {
	display: flex;
	gap: 100px;
	flex-direction: column;
}

.areaItem {
	display: flex;
	gap: 25px;
}

.areaImg {
	flex: 1 0 15vw;
	width: 15vw;
	height: 10vw;
	overflow: hidden;

	// & > img {
	// 	width: 100%;
	// 	height: 100%;
	// 	object-fit: cover;
	// }
}

.map {
	flex: 1 1 100%;
	margin-top: 15px;
}

.areaDoc {
	margin-top: 10px;
}

@media (max-width: 768px) {
	.carousel {
		display: none;
	}


	// .imageLitleContainer {
	// 	height: 25vw;
	// }

	.areaItem {
		flex-direction: column;
	}

	.areaImg {
		flex: 1 1 100%;
		width: 100%;
		height: 25vw;
	}
}

@media (max-width: 425px) {
	// .imageLitleContainer {
	// 	height: 50vw;
	// }

	// .imageContainer {
	// 	height: 20vh;
	// }
}
