@import "../../style/color.scss";




@media (max-width: 480px) {
	.little {
		display: flex;
		align-items: center;
		font-family: $font;
		font-size: 20px;
		gap: 7px;
		color: $first-color;

		& img {
			height: 50px;
		}
	}
}