@import "../../style/color.scss";

.search {
	margin-top: 10px;
	margin-bottom: 15px;
	// max-width:150px
	height: 40px;

}

.form {
	display: flex;
	align-items: center;
	gap:5px;
	line-height: 20px;
}