@import "../../../../style/color.scss";

.container {
	display: flex;
	flex-direction: column;
	gap:15px;
	min-height: 360px ;
}

.pagination {
	margin-top: 15px;
	margin-left: -2px;
}