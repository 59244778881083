.title {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 15px;
}

@media (max-width: 480px) {
	.title {
		align-items: flex-start;
	}
}