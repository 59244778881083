@import "../../style/color.scss";

.flex_container {
	display:flex;
	gap: 15px
}

.text {
	white-space: pre-wrap;
	line-height: 20px;
}

.img>img {
	min-width: 350px;
	max-width: 100%;
}