@import "../../style/color.scss";

.flex_container {
	&>form {
		display: flex;
		gap: 15px;
	}
}

.resultList {
	margin-top: 25px;
	display: flex;
	gap: 25px;
	flex-direction: column;
}

.text {
	white-space: pre-wrap;
	line-height: 20px;
}

.img>img {
	min-width: 350px;
	max-width: 100%;
}

.content {
	width:100%;
}

.itemList {
	font-size: 30px;
	font-family: "TTBricksBold";
	padding-bottom: 15px;
	margin: 0;
	color: $first-color;
	text-decoration: underline;
	opacity: 0.8;

	&:hover {
		opacity: 1;
	}
}

.type {
	color: #666;
}