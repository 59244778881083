.form {
	display: flex;
	flex-direction: column;
	gap: 3px;
	max-width: 650px;
	margin-top: 15px;

	& div {
		border-radius: 0px;
	}
}


.button {
	// margin-top: 25px;
	display: flex;
	justify-content: flex-end;
	gap: 7px;
}

.formGroup {
	display: flex;
	gap: 25px;
}

.formGroupActor {
	display: flex;
	gap: 5px;
	margin-bottom: 20px;
}

.actorItem {
	padding: 5px;
	background-color: #eee;
}

.textEditor {
	padding-bottom: 20px;
}

.prewActor {
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

@media (max-width: 480px) {
	.formGroup {
		flex-direction: column;
		gap: 3px;
	}
}


// .step {
// 	margin-bottom: 20px;
// }

