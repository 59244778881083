@import "../../../style/color.scss";

.showList {
	display: grid;
	grid-template-columns: minmax(250px, 1fr) minmax(250px, 1fr);
	gap: 25px;
	max-width: 100%;
}

.showItem {
	width: 500px;

	.showImage {
		width: 100%;
		height: 15vw;
		overflow: hidden;
	}

	img {
		object-fit: cover;

		&:hover {
			transform: scale(2);
		}
	}
}

.showTitle {
	font-size: 24px;
	font-family: "TTBricksBold";
	color: $five-color;
	padding-bottom: 10px;
	transition: all 0.5s ease;
	text-transform: uppercase;

	&:hover {
		color: $first-color;
	}
}

.place {
	margin-bottom: 5px;
	color: $five-color;
	font-size: 14px;
}

.placeTitle {
	font-weight: 600;
}

.placeName {
	display: inline-block;
	&::first-letter {
		text-transform: uppercase;
	}
}

.events {
	display: flex;
	flex-wrap: wrap;
	gap: 3px;
	margin-bottom: 10px;
}

.eventTitle {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 7px;
	color: $five-color;
}

.eventStyle {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: $five-color;
	border: 1px solid $five-color;
	min-height: 30px;

	padding: 6px 8px;
	cursor: pointer;
	transition: all 0.5s ease;
	opacity: 0.8;
	text-align: center;

	&:hover {
		opacity: 1;
		color: $first-color;
		border: 1px solid $first-color;
	}
}

.transfer {
	font-size: 10px;
	padding-top: 2px;
	color: $five-color;
}

.carouselItem {
	position: relative;
	width: 100%;
   padding-top: 56.25%;
}

.carouselImg {
	position:  absolute;
	top: 0;
   left: 0;
   bottom: 0;
   right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media (max-width: 768px) {

	.showList {
		grid-template-columns: 100%;
	}
}
