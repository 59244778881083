@import "../../style/color.scss";

@media (hover: hover) and (pointer: fine) {
}

.container {
	display: flex;
	z-index: -10;
}



.contentWrapper {
	display: flex;
	justify-content: center;
	min-height: 100vh;
	width: 100%;
}

.content {
	padding: 20px;
	width: 95%;
	min-width: 280px;
}

.contentContainer {
	max-width: 300px;
	background-color: red;
}

.cardContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 10px;
}

.innerContent {
	min-width: 100px;
	max-height: 500px;
	max-width: 300px;
	background-color: $first-color;
	color: #fff;
	padding: 15px;
}

.dashboardWrapper {
	background-color: $first-color;
	min-height: 100vh;
	width: 250px;
	color: #fff;
	z-index: 200;
	transition: transform 0.1s ease-in-out;
	-webkit-box-shadow: 10px 0px 10px 2px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 10px 0px 10px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 10px 0px 10px 2px rgba(0, 0, 0, 0.2);
}

.dashboardContent {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: 20px;
	overflow: auto;
	position: sticky;
	top: 0;
	height: 95vh;
	
	scrollbar-gutter: stable;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background: #fff;
		border-radius: 2px;
		border: 2px solid $first-color;
	}
}


.topNavbar {
	display: flex;
	justify-content: space-between;
	top: -60px;
	position: absolute;
	transition: transform 0.1s ease-in-out;
	-webkit-box-shadow: 0px 8px 5px 0px rgba(102, 102, 102, 0.2);
	-moz-box-shadow: 0px 8px 5px 0px rgba(102, 102, 102, 0.2);
	box-shadow: 0px 8px 5px 0px rgba(102, 102, 102, 0.2);
	display: none;
}

@media (max-width: 768px) {
	.cardContainer {
		grid-template-columns: 1fr 1fr;
		gap: 5px;
	}
}

@media (max-width: 480px) {
	.dashboardWrapper {
		width: 250px;
	}

	.content {
		width: 100%;
	}

	.nameOrg {
		display: flex;
		align-items: center;
		font-family: $font;
		font-size: 20px;
		gap: 7px;
		color: $first-color;

		& img {
			height: 50px;
		}
	}

	.topNavbar {
		display: flex;
		height: 50px;
		position: sticky;
		top: 0;
		z-index: 100;
		padding: 20px;
		background-color: #fff;
		transform: translateY(0px);
	}

	.innerContent {
		max-width: 100%;
	}

	.dashboardWrapper {
		position: absolute;
		top: 0;
		transform: translateX(-300px);
	}

	.open {
		position: fixed;
		transform: translateX(0px);
	}

	.cardContainer {
		grid-template-columns: 1fr;
		gap: 5px;
	}
}
