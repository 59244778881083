@import "../../../style/color.scss";

.showInformation {
	display: flex;
	flex-direction: column;
	// margin-top: 50px;
	max-width: 70vw;
	color: #333;
}

.carousel {
	margin-bottom: 35px;
}

.imageContainer {
	height: 580px;
}

.carouselImg {
	width: 100%;
	height: 100%;
	object-fit: cover
}

.pagingDotsClassName {
	position: relative;
	top: 30px;
}

.paramBlock {
	margin-bottom: 5px;
}

.paramTitle {
	font-weight: 600;
	font-size: 16px;
	display: inline-block;
}

.name {
	display: inline-block;
	// text-transform: uppercase;
	margin-right: 5px;
	&::first-letter {
		text-transform: uppercase;
	}

	& div {
		display: inline-block;
		margin-right: 5px;
		text-decoration: underline;
	}

	& a {
		color: $five-color;
		text-decoration: underline;

		&:hover {
			color: $first-color;
		}
	}
}

.age {
	margin-top: 25px;
	margin-bottom: 15px;
}

.pushkinImg {
	height: 100px;
}

.ageTitle {
	font-size: 48px;
	font-weight: 900;
	color: $five-color;
}

.additionally {
	margin-top: 7px;
	margin-bottom: 7px;
}

.additionallyText {
	white-space: pre-wrap
}

.description {
	margin-top: 25px;
	line-height: 1.5;
}

.saleTitle {
	font-size: 28px;
	font-family: "TTBricksBold";
	padding-bottom: 15px;
	text-align: center;
}

.events {
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-bottom: 10px;
}

.eventStyle {
	color: $first-color;
	border: 1px solid;
	text-align: center;
	font-weight: 600;
	font-size: 18px;
	padding: 7px;
	cursor: pointer;
	transition: all 0.5s ease;
	opacity: 0.8;

	&:hover {
		opacity: 1;
		color: $first-color;
	}
}

.time {
	display: block;
	margin-top: 5px;
	padding-top: 5px;
	border-top: 1px solid;
	font-size: 30px;
}

.transfer {
	font-size: 12px;
	padding-top: 2px;
	color: $first-color;
}

@media (max-width: 768px) {
	.imageContainer {
		height: 450px;
	}
	.showInformation {
		width: 100%;
	}

	.events {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 5px;
		margin-bottom: 10px;
	}

	.eventStyle {
		color: $first-color;
		border: 1px solid;
		text-align: center;
		font-weight: 600;
		font-size: 24px;
		padding: 7px;
		cursor: pointer;
		transition: all 0.5s ease;
		opacity: 0.8;
	}

	.time {
		margin-top: 5px;
		padding-top: 5px;
		border-top: 1px solid;
		font-size: 24px;
	}
}

@media (max-width: 425px) {
	.imageContainer {
		height: 150px;
	}

	.showInformation {
		max-width: 100%;
	}

	.ageTitle {
		font-size: 36px;
	}

	
}