.social {
	margin-top: 15px;
	gap: 8px;
	display: flex;
	justify-content: flex-end;

	& img {
		height: 30px;
	}

	& img:hover {
		transform:scale(1.1)
	}

	& img:active {
		transform:scale(0.9)
	}
}