@import "../../style/color.scss";

@media (hover: hover) and (pointer: fine) {
}



.menu {
	text-align: left;
	margin-top: 15px;
	margin-bottom: 15px;

	
	& a {
		text-decoration: none;
		color: #ccc;
	}

	.mainMenu {
		font-size: 18px;
		font-weight: 400;
		line-height: 38px;

		& li a:hover {
			color: #fff !important;
		}
	}








}

