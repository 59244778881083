@import "./style/color.scss";

@font-face {
  font-family: "TTBricksBold";
  src: local("TTBricksBold"), url("./fonts/TTBricksBold.ttf") format("truetype");
  font-weight: bold;
}

@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

body {
  margin: 0;
  font-family: Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  min-width: 320px;
  scrollbar-gutter: stable;
  // overflow-anchor: none;
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-thumb {
  background: $first-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}

#root {
  min-width: 320px;
}

.special-version {
  position: fixed !important;
  margin: 0 auto;
  right: 0;
  top:0;
}


.swiper-pagination-bullet-active {
  background-color: $first-color !important;
}
.active {
  color: #fff !important;
}

#ticketland-popup__close {
  top: 5px !important;
  right: 5px !important;
}

.homeNewsWrapper {

	.MuiGrid2-direction-xs-row {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.MuiGrid2-container {
		margin-top: 0 !important;
		padding: 0 !important;
	}

}
