@import "../../../style/color.scss";

.teamItem {
	display: flex;
	flex-direction: column;
	font-size: 24px;
	font-family: "TTBricksBold";
	color: $five-color;
	padding-bottom: 10px;
	transition: all 0.5s ease;
	text-transform: uppercase;
	cursor: pointer;
}

.teamItem a {
	font-size: 24px;
	font-family: "TTBricksBold";
	color: #333;
	padding-bottom: 10px;
	transition: all 0.5s ease;
	text-transform: uppercase;

	&:hover {
		color: $first-color;
	}
}

.artistImg {
	// width: 100%;
	// height: 20vw;
	margin-bottom: 7px;
	overflow: hidden;
	aspect-ratio: 3 / 4;

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;

		.teamItem &:hover,
		&:focus{
			transform: scale(1.1);
			transition: all 1.1s ease
		}
	}
}

@media (max-width: 1024px) {
	// .artistImg {
	// 	height: 30vw;
	// }
	
}

@media (max-width: 768px) {
	// .artistImg {
	// 	height: 30vw;
	// }
	
}

@media (max-width: 480px) {
	// .artistImg {
	// 	height: 60vw;
	// }
	
}
