@import "../../style/color.scss";

@media (hover: hover) and (pointer: fine) {
}



.menu {
	text-align: right;

	
	& a {
		text-decoration: none;
		color: #ccc;
	}

	.mainMenu {
		font-size: 18px;
		font-weight: 400;
		line-height: 38px;

		& li a:hover {
			color: #fff !important;
		}
	}

	.dropdownItem {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;
		right: -7px;
		cursor: pointer;
		width: 100%;
		color:#ccc;
	}

	.subMenu {
		text-align: right;
		background-color: $first-color;
		font-size: 14px;
		line-height: 20px;
		max-height: 0px;
		transition: max-height 0.5s ease-in;
		// margin-right: -20px;
		overflow: hidden;
		padding: 0px 20px;
		// width: 100px;

		& li {
			cursor: pointer;
			padding-top: 5px;
			padding-bottom: 5px;
		}

		& li a:hover {
			color: #fff !important;
		}
	}

	.openSubMenu {
		max-height: 500px;
		// display: block !important;
	}

	

}

.button {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 10px;
	border-bottom: 1px solid #ccc;
}

.active {
	background-color:  red;
	color: #fff !important;
}
