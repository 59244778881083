.inputFile {
	line-height: 30px;
	font-size: 24px;
}

.imageContainer {
	display: grid;
	gap: 5px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	grid-auto-rows: 1fr;
	flex-wrap: wrap;
	// justify-content: stretch;
	// min-height: 100px;
	padding: 10px 0px

}

.imgItem {
	position: relative;
	max-height: 100px;
}

.img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	object-position: center center;
}

@media (max-width: 480px) {
	.imageContainer {
		grid-template-columns: 1fr 1fr;
	}
}
