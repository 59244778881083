@import "../../style/color.scss";

@media (hover: hover) and (pointer: fine) {
}

.dropdownItem {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	//right: -7px;
	cursor: pointer;
	width: 100%;
	color:#ccc;


	& svg {
		transition: all 0.3s ease;
		transform: rotate(-90deg);
		width: 30px;
		height: 30px;
		margin-left: 5px;
	}
}

.subMenu {
	text-align: left;
	background-color: $first-color;
	font-size: 14px;
	line-height: 20px;
	transition: opacity 0.2s ease-in;
	// margin-right: -20px;
	overflow: hidden;
	padding: 0px 20px;
	// width: 100px;
	max-height: 0;
	opacity: 0;

	& li {
		cursor: pointer;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	& li a:hover {
		color: #fff !important;
	}
}

.openSubMenu {
	opacity: 1;
	max-height: initial;
	padding-bottom: 10px;
}

.active {
	background-color:  red;
	color: #fff !important;
}

.rotate>svg {

}

.rotate>svg {
	transform: rotate(90deg);
}