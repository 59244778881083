@import "../../style/color.scss";

.flex_container {
	display:flex;
	gap: 15px;

	&>div:nth-child(1) {
		flex:1 1 60%;
	}
	&>div:nth-child(2) {
		flex:1 1 40%;
	}
}

.text {
	white-space: pre-wrap;
	line-height: 20px;
}

.img>img {
	min-width: 350px;
	max-width: 100%;
}

.imgList {
	display: flex;
	flex-direction: column;
	gap:10px;
}

@media (max-width: 1024px) {
	.flex_container {
		flex-direction: column;
	}
}