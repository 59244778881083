.container {
	position: absolute;
	top:0;
	left:0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	// background-color: #666;
}

.button {
	display: flex;
	justify-content: space-between;
}

.showErrorMessage {
	padding-bottom: 20px;
	color: red;
}
