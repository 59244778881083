@import "../../style/color.scss";

.ShowListContainer {
	height: 300px;
	padding: 10px 0px;

}

.showItem {
	padding: 10px;
	
	background-color: $first-color;
	
	height: 100%;
	max-width: 100%;
	box-sizing: border-box;
	border: 1px solid rgba(0, 0, 0, 0.05);
	box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.showItemImg {
	position: relative;
	width: 100%;
   padding-top: 56.25%;
}

.showItemImg img {
	border: 1px solid rgba(255, 255, 255, 0.5);
	position:  absolute;
	top: 0;
   left: 0;
   bottom: 0;
   right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.showName {
	text-transform: uppercase;
	font-size: 16px;
	font-family: "TTBricksBold";
	margin-top: 10px;
	color: #fff;
	opacity: 0,7;
	transition: all 0.5s ease;

	&:hover {
		opacity: 1;
	}
}

@media (max-width: 890px) {
	// .showItemImg {
	// 	border: 1px solid rgba(255, 255, 255, 0.2);
	// 	height: 20vh;
	// }
}