.impaired {
	font-size: 12px;
	margin-top:15px
}

#id {
	color: #fff;
	padding: 5px;
	border:1px solid #fff;
	
}