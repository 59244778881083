@import "../../../../style/color.scss";


.search {
	margin-bottom: 35px;
	// max-width: 500px;
}

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	// gap: 25px;
	margin-bottom: 35px;
	min-height: 465px;
	
}

.pagination {
	margin-bottom: 15px;
}

@media (max-width: 480px) {
	
}