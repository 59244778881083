@import "../../style/color.scss";

.afishaPage {
	width: 100%;
}

.monthPanel {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	width: 100%;

	& button {
		color: $first-color;
		background-color: #fff;
		border: 1px solid $first-color;
		padding: 0;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: all .2s ease;

		&:active,
		&:hover {
			color: #fff;
			background-color: $first-color;
		}


	}
}

.monthItem {
	font-size: 36px;
	font-family: "TTBricksBold";
	color: #333;
	// padding-bottom: 10px;
	transition: all 0.5s ease;
	text-transform: uppercase;
	text-align: center;
	cursor: pointer;
	flex-wrap: nowrap;

	&:hover {
		color: $sub-first-color;
	}
}

.activeMonth {
	text-decoration: underline;
	color: $first-color;
}

.daysPanel {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-template-rows: repeat(5, minmax(150px, auto));
	gap: 5px;
	margin-top: 10px;
	overflow-x: scroll;
	height: auto;
	// scrollbar-gutter: stable;
	max-width: 100%;
	margin-bottom: 5px;
}

.daysPanel::-webkit-scrollbar {
	
	height: 8px;
}

.daysPanel::-webkit-scrollbar-thumb {
	border-top: 3px solid #fff;
	background: $first-color;
}

.dayItem {
	background: $first-color;
	color: #fff;
	padding: 10px 10px 20px 10px;
}

.upLine {
	display: flex;
	justify-content: space-between;
	gap: 5px;
	border-bottom: 1px solid rgba(205, 185, 255, .2);
	padding-bottom: 2px;

}

.dayOfWeek {
	font-size: 24px;
	font-weight: normal;
	text-transform: uppercase;
	font-family: "TTBricksBold";
	color: #CDB9FF;
}

.dayNumber {
	font-size: 24px;
	font-family: "TTBricksBold";
}

.disabled {
	background: #E9E9EA;

	.upLine {
		border-bottom-color: transparent;
	}

	.dayOfWeek {
		color: rgba(51, 51, 51, .2);
	}

}

.currentDay {
	background-color: #6138C8;
}

.events {
	margin-top: 17px;
	font-size: 13px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	font-weight: normal;
	text-transform: uppercase;
}

.notFound {
	padding-top: 25px;
	text-align: center;
	font-size: 24px;
	font-family: "TTBricksBold";
}

.event {
	opacity: 1;
	transition: all 0.3s ease;

	&:not(.eventSold):hover {
		transform: translateX(3px);
		cursor: pointer;
	}

	& > p {
		display: inline-block;

		&:nth-child(1) {
			color: #fff;
			font-size: 11px;
			margin-right: 5px;
			padding-right: 5px;
			border-right: 1px solid rgba(205, 185, 255, .2);
			margin-bottom: 1px;
		}

		&:nth-child(2) {
			font-size: 11px;
			color: #CDB9FF;
			margin-bottom: 1px;
		}
	}

	&.eventSold {
		color: #CDB9FF;

		& > p {
			display: inline-block;

			&:nth-child(1) {
				color: #CDB9FF;
			}

		}

	}


}

.soldNote {
	display: flex;
	margin-top: 10px;
}

.soldNote > div {
	color: #FFF;
	border: 1px solid #FFF;
	font-size: 11px;
	padding: 0px 8px 1px 8px;
	text-transform: none;
	line-height: 1.3;
}

.perfName {
	padding-left: 0px !important;
}

.transfer {
	padding-top: 4px;
	font-size: 11px;
	color: #CDB9FF;
	text-transform: none;
}

.opaciti {
	opacity: 0;
	// cursor: default;
}

.frameBox {
	height: 100%;
}

.afishaFilter {
	margin: 0 0 10px 0;
}

.afishaFilter_row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
	align-items: center;
}

.afishaFilter_col {
	padding: 0 15px;
	flex-basis: initial;
	flex-grow: 0;
	max-width: 100%;
	box-sizing: border-box;
}

.afishaFilter_col:first-child {
	flex: 0 0 365px;
}

.formCheckbox {

	input {
		position: absolute;
		visibility: hidden;
	}

	label {
		position: relative;
		padding: 0 0 0 35px;
		font-size: 16px;
		font-weight: normal;
		text-transform: uppercase;
		cursor: pointer;
		display: block;
	}

	label:before {
		content: "";
		background-color: #fff;
		background-image: url(../../img/checkbox.svg);
		background-position: center center;
		background-size: 0 0;
		background-repeat: no-repeat;
		width: 18px;
		height: 18px;
		border: 1px solid #37246A;
		position: absolute;
		left: 0;
		top: -2px;
		transition: all .15s ease;
	}

	input:checked + label:before {
		background-color: #37246A;
		border: 1px solid #37246A;
		background-size: 13px 10px;
	}

}

@media (max-width: 768px) {
	.daysPanel {
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: inherit;
		gap: 10px;
		margin-top: 10px;
	}

	.monthPanel {
		gap: 3px;

		& button {
			color: #fff;
			background-color: $first-color;
			border: none;
			padding: 15px 15px;
			cursor: pointer;

			&:active {
				transform: scale(0.9);
			}

			&:hover {
				opacity: 0.7;
			}
		}
	}

	.transfer {
		font-size: 12px;
	}

	.monthItem {
		font-size: 24px;
	}

	.dayItem {
		padding: 13px 20px 20px 20px;

	}



	.dayOfWeek {
		font-size: 30px;
	}

	.dayNumber {
		font-size: 30px;
	}

	.upLine {
		padding-bottom: 11px;
	}

	.events {
		font-size: 18px;
		line-height: 1.2;
		margin-top: 13px;
		gap: 23px;
	}

	.event {

		& > p {
			display: inline-block;

			&:nth-child(1) {
				font-size: 13px;
				margin-right: 10px;
				padding-right: 10px;
				margin-bottom: 7px;
			}

			&:nth-child(2) {
				font-size: 13px;
				margin-bottom: 7px;
			}
		}

	}

	.afishaFilter_row {
		margin-left: -10px;
		margin-right: -10px;
		flex-wrap: nowrap;
	}

	.afishaFilter_col {
		padding-left: 10px;
		padding-right: 10px;
	}

	.afishaFilter_col:first-child {
		flex: 0 0 200px;
	}

	.formCheckbox {

		label {
			font-size: 12px;
			line-height: 1;
			padding-left: 30px;
		}

		label:before {
			top: 50%;
			margin-top: -11px;
		}

	}

}

// react select


.reactSelect {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.formGroup {

}






