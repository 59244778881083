@import "../../../../style/color.scss";

.line {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	color: $five-color;
	font-family: 'TTBricksBold';
	font-size: 16px;
	line-height: 35px;

	&:hover {
		color: $first-color;
	}
}

.fio {
	font-size: 24px;
	opacity: 0.85;
	text-transform: uppercase;


}

@media (max-width: 480px) {
	.line {
		margin-top: 10px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
}