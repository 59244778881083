@import "../../../style/color.scss";

.teamItem {
	display: flex;
	justify-content: center;
	font-size: 24px;
	font-family: "TTBricksBold";
	color: $five-color;;
	padding-bottom: 10px;
	transition: all 0.5s ease;
	text-transform: uppercase;
	cursor: default;
	text-align: center;
}

.teamItem a {
	font-size: 24px;
	font-family: "TTBricksBold";
	color: #333;
	padding-bottom: 10px;
	transition: all 0.5s ease;
	text-transform: uppercase;
	text-align: center;
	

	&:hover {
		color: $first-color;
	}
}

.title {
	font-size: 38px;
	font-family: "TTBricksBold";
	padding-bottom: 15px;
	color: $first-color;
	display: flex;
	justify-content: center;
	text-align: center;
}