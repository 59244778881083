@import "../../style/color.scss";

.mainTitle {
	font-size: 38px;
	font-family: 'TTBricksBold';
	padding-bottom: 15px;
	margin: 0;
}

.title {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 15px;
}


@media (max-width: 480px) {
	.title {
		align-items: flex-start;
	}

	.mainTitle {
		font-family: 'TTBricksBold';
		padding-bottom: 15px;
		font-size: 30px;
	}
}