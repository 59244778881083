@import "../../style/color.scss";

.flex_container {
	display: flex;
	gap: 15px;

	& menu,
	ol,
	ul {
		list-style: inherit;
		padding-left: 40px;
	}

	

	& h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font: none;
	}
}

.text {
	white-space: pre-wrap;
	line-height: 20px;
}

.img > img {
	min-width: 350px;
	max-width: 100%;
}
