@import "../../../style/color.scss";

.showList {
	display: grid;
	grid-template-columns: 1fr;
	gap: 50px;
	max-width: 100%;
	padding-top: 5%;
}

.showItem {
	width: 500px;

	.showImage {
		width: 350px;
		height: 150px;
		overflow: hidden;
	}

	img {
		object-fit: cover;

		&:hover {
			transform: scale(2);
		}
	}
}

.showTitle {
	font-size: 24px;
	font-family: "TTBricksBold";
	color: $five-color;
	padding-bottom: 2px;
	transition: all 0.5s ease;
	text-transform: uppercase;

	&:hover {
		color: $first-color;
	}
}

.place {
	margin-bottom: 5px;
	color: $five-color;
	font-size: 14px;
	cursor:default;
}

.placeTitle {
	font-weight: 600;
}

.placeName {
	display: inline-block;
	// text-transform: uppercase;
	&::first-letter {
		text-transform: uppercase;
	}
}

.events {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 3px;
	margin-bottom: 10px;
}

.eventTitle {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 7px;
	color: $five-color;
}

.eventStyle {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: $five-color;
	border: 2px solid $five-color;
	font-weight: 600;
	padding: 6px 8px;
	cursor: pointer;
	transition: all 0.5s ease;
	opacity: 0.8;
	text-align: center;

	&:hover {
		opacity: 1;
		color: $first-color;
		border: 2px solid $first-color;
	}
}

.transfer {
	font-size: 12px;
	padding-top: 2px;
	color: $five-color;
}

@media (max-width: 768px) {

	.showList {
		grid-template-columns: 100%;
	}
}
