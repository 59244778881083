@import "../../style/color.scss";

.mainPage {
	// max-width: 1200px;
	width: 100%;
}

.carousel {
	// max-width: 1200px;
	width: 100%;
}

.imageContainer {
}

.carouselItem {
	position: relative;
	width: 100%;
   padding-top: 56.25%;
}

.carouselImg {
	position:  absolute;
	top: 0;
   left: 0;
   bottom: 0;
   right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.showInformation {
	display: grid;
	grid-template-columns: 1fr, 1fr, 1fr;
	grid-template-rows: auto, auto, auto, auto;
	padding-top: 15px;
	padding-bottom: 15px;
	gap: 7px;
	// min-height: 155px;
}

.showTitle {
	grid-column: 1 / 2;
	grid-row: 1 / 2;
	text-transform: uppercase;
	padding-bottom: 5px;
	font-size: 24px;
	font-family: "TTBricksBold";
	color: $five-color;
	padding-bottom: 10px;
	transition: all 0.5s ease;
	text-transform: uppercase;
	// display: contents;
	// min-height: 70px;

	& > a {
		transition: all 0.5s ease;
		color: $five-color;
		text-transform: uppercase;

		&:hover {
			color: $first-color;
		}
	}
}

.showDescription {
	grid-column: 1 / 2;
	grid-row: 2 / 3;
	background-color: white;
	padding-bottom: 15px;
}

.eventStyleBlock {
	grid-column: 3 / 4;
	grid-row: 1 / 5;
}

.eventStyle {
	background-color: #fff;
	color: $first-color;
	border: 1px solid;
	text-align: center;
	// font-weight: 600;
	font-size: 18px;
	padding: 15px;
	transition: all 0.5s ease;
	// cursor: default;
	cursor: pointer;
	opacity: 0.6;

	& > :first-child {
		border-bottom: 1px solid $first-color;
	}

	&:hover {
		opacity: 1;
	}

	& > :first-child {
		padding-bottom: 7px;
	}
	& > :nth-child(2) {
		padding-top: 12px;
	}
}

.saleButtonBlock {
	grid-column: 4 / 5;
	grid-row: 1 / 5;
}

.saleButton {
	background-color: transparent;
	color: $first-color;
	border: none;

	// border: 1px solid;
	text-align: center;
	font-weight: 600;
	font-size: 18px;
	padding: 25px 20px 0px 20px;
	cursor: pointer;
	transition: all 0.5s ease;
	width: 100%;
}

.sectionHeader {

	margin-top: 60px;
	margin-bottom: 20px;
	display: flex;
	align-items: flex-end;

	h2 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		padding-right: 30px;
	}

	.allLink {
		margin-left: auto;
		margin-top: 7px;
	}

}

.homeAbout {
	display: flex;
	margin: 0 -15px;
	box-sizing: border-box;

	* {
		box-sizing: border-box;
	}

	.homeAboutCol {
		flex: 0 0 50%;
		padding: 0 15px;
	}

	.homeAboutPic img {
		width: 100%;
	}

	.homeAboutDescr {
		min-height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	p:not(:last-child) {
		margin-bottom: 15px;
	}


}

@media (max-width: 1440px) {
	// .carouselItem {
	// 	height: 500px;
	// }
}

@media (max-width: 1024px) {
	// .carouselItem {
	// 	height: 300px;
	// }
}

@media (max-width: 770px) {
	// .carouselItem {
	// 	height: 200px;
	// }

	.showTitle {
		grid-column: 1 / 5;
		grid-row: 1 / 2;
	}

	.showDescription {
		grid-column: 1 / 5;
		grid-row: 2 / 3;
	}

	.eventStyleBlock {
		grid-column: 1 / 5;
		grid-row: 3 / 4;
	}

	.saleButtonBlock {
		grid-column: 1 / 5;
		grid-row: 4 / 5;
	}

	.sectionHeader {
		flex-wrap: wrap;
		margin-top: 45px;

		h2 {
			padding-right: 0;
			flex: 0 0 100%;
		}

		.allLink {
			flex: 0 0 100%;
		}

	}

	.homeAbout {

		flex-wrap: wrap;

		.homeAboutCol {
			flex: 0 0 100%;
		}

		.homeAboutPic {
			margin-bottom: 30px;
		}

		.homeAboutDescr {
			min-height: initial;
		}

	}

}

@media (max-width: 480px) {
	// .carouselItem {
	// 	height: 48vw;
	// }

	.showInformation {
		display: grid;
		padding-top: 15px;
		padding-bottom: 15px;
		gap: 7px;
		// height: 400px;
	}

	.showTitle {
		grid-column: 1 / 5;
		grid-row: 1 / 2;
	}

	.showDescription {
		grid-column: 1 / 5;
		grid-row: 2 / 3;
	}

	.eventStyleBlock {
		grid-column: 1 / 5;
		grid-row: 3 / 4;
	}

	.saleButtonBlock {
		grid-column: 1 / 5;
		grid-row: 4 / 5;
	}
}
